
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Robots',
  
  created(){
    window.location.href = "/robots.txt";
  }
});
